import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material"
import ImageAstDialog from "./ImageAstDialog"
import { useState } from "react"
import { dateWithTime } from "../config"

export const BacklogsResume = ({
    setOpenResumeBacklogsDialog,
    openResumeBacklogsDialog,
    contenidoResumenBacklog
}) => {
    const [openImageModel, setOpenImageModel] = useState(false)

    const [indexImage, setIndexImage] = useState(0)
    const [imagesSelected, setImagesSelected] = useState([])

    const openImage = (images, index) => {
        setOpenImageModel(true)
        setImagesSelected(images)
        setIndexImage(index)
    }

    const closeImage = () => {
        setOpenImageModel(false)
        setImagesSelected([])
        setIndexImage(0)
    }

    const isOdd = (num) => {
        return num % 2
    }

    return (
        <Dialog
                open={openResumeBacklogsDialog}
                onClose={() => {setOpenResumeBacklogsDialog(false)}}
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "700px",
                        height: "100%",
                        maxHeight: "600px"
                      },
                    }
                  }}
    
            >
                {openImageModel && <ImageAstDialog
                    open={openImageModel}
                    handleClose={closeImage}
                    images={imagesSelected}
                    indexSlide={indexImage}
                />}
                <DialogTitle>
                    Resumen Backlogs
                </DialogTitle>
                <DialogContent>
                    {
                        contenidoResumenBacklog &&
                        <div style={{width: '100%', overflowY: 'auto'}}>
                            {
                                Object.keys(contenidoResumenBacklog).map((key, i) => {
                                    return (
                                        <div key={i} style={{width: '100%', paddingBotom: 50, padding: 10, borderBottom: '1px #ccc solid', backgroundColor: isOdd(i) ? '#f0f0f0' : '#fff'}}>
                                            <p style={{margin: '0px 5px', fontSize: 16}}>
                                                <strong>{key}</strong>
                                            </p>
                                            <div>
                                                {
                                                    contenidoResumenBacklog[key].length === 0
                                                    ?
                                                    <p style={{margin: '0px 5px', fontSize: 14}}>Sin datos</p>
                                                    :
                                                    <div>
                                                        {
                                                            contenidoResumenBacklog[key].map((item, n) => {
                                                                return (
                                                                    <div key={n}>
                                                                        <p style={{margin: '0px 5px', fontSize: 14}}>{item.taskdesc}</p>
                                                                        <p style={{margin: '0px 5px', fontSize: 14}}>Mensajes: </p>
                                                                        {
                                                                            item.backlogList.map((el, z) => {
                                                                                return (
                                                                                    <div key={z} style={{position: 'relative', border: '1px #ccc solid', borderRadius: 8, padding: 5, marginBottom: 10}}>
                                                                                        <div style={{width: '100%'}}>
                                                                                            <p style={{margin: 0, fontSize: 14}}>
                                                                                                <strong>
                                                                                                    - {el.user.name} {el.user.lastName}
                                                                                                </strong>
                                                                                            </p>
                                                                                        </div>
                                                                                        <div style={{width: '100%'}}>
                                                                                            <p style={{margin: 0, whiteSpace: 'pre-line'}}>
                                                                                                {el.message}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div style={{width: '100%', textAlign: 'right', fontSize:12}}>
                                                                                            <p style={{margin: 0}}>
                                                                                                {dateWithTime(el.id)/* dateV2(el.id, 'full') */}
                                                                                            </p>
                                                                                        </div>
                                                                                        <Grid container>
                                                                                            {
                                                                                                el.fileList && el.fileList.map((image, index) => {
                                                                                                    return (
                                                                                                        <Grid item xs={2} key={index} style={{margin: '0px 10px'}}>
                                                                                                            <img src={image} style={{width:'100%', height: '100%'}}
                                                                                                                onClick={() => {openImage(el.fileList, index)}}
                                                                                                            />
                                                                                                        </Grid>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Grid>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </DialogContent>
            </Dialog>
    )
}