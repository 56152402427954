export const dateV2 = (value, type) => {
    if (value) {
        const date1 = new Date(value)
        const dateMilisegundos = date1.getTime()
        const offset = date1.getTimezoneOffset()
        const milisegundosMenos = dateMilisegundos - ((60) * offset)
        const date = new Date(milisegundosMenos)
        const hora = (date.getHours() > 9) ? date.getHours() : ('0' + date.getHours())
        const minutos = (date.getMinutes() > 9) ? date.getMinutes() : ('0' + date.getMinutes())
        const segundos = (date.getSeconds() > 9) ? date.getSeconds() : ('0' + date.getSeconds())
        const year = date.getFullYear()
        const month = ((date.getMonth() + 1) > 9) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))
        const day = (date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())
        if (type === 'full') {
            const fecha = `${day}-${month}-${year} ${hora}:${minutos}:${segundos}`
            return fecha
        } else if (type === 'yyyy/mm/dd') {
            const fecha = `${year}/${month}/${day}`
            return fecha
        } else if (type === 'dd/mm/yyyy') {
            const fecha = `${day}/${month}/${year}`
            return fecha
        } else {
            return ''
        }
    } else {
        return 'Sin información'
    }
}