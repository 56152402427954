import axios from 'axios'

export default {
    getPatterns: () => {
        return axios.get('/patterns/getPatterns')
    },
    savePattern: (pattern) => {
        return axios.post('/patterns/savePattern', pattern)
    },
    getPatternDetails: () => {
        return axios.get('/patterns/getPatternDetails')
    },
    getPatternsFields: async () => {
        const response = await  axios.get('/patterns/getPatternsFields')
        return response.data
    },
    getPatternsDetailByIdpm: (idpm) => {
        return axios.post('/patterns/getPatternsDetailByIdpm', {idpm: idpm})
    },
    getPatternDetailByIdpmAndGuide: async (idpm, type) => {
        const response = await axios.post('/patterns/getPatternDetailByIdpmAndGuide', {idpm, type})
        return response.data
    },
    saveFilesToPattern: (file, patternId) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('patternId', patternId)
        const config = {
            headers: { "Content-Type": "multipart/form-data" },
        }
        return axios.post('/patterns/saveFilesToPattern', formData, config)
    }
}