import { Drawer } from "@mui/material"
import { useEffect, useState } from "react"
import { PDFFileDialog } from "../../dialogs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/free-solid-svg-icons"

export const MenuDocumentos = ({documents, openMenu, setOpenMenu}) => {
    const [openPDF, setOpenPDF] = useState(false)

    const [fileSelected, setFileSelected] = useState('')

    const selectFile = (url) => {
        setFileSelected(url)
        setOpenPDF(true)
    }

    useEffect(() => {
        if (!openPDF) {
            setFileSelected('')
        }
    }, [openPDF])

    return (
        <Drawer open={openMenu} onClose={() => {setOpenMenu(false)}} anchor='right'>
            <PDFFileDialog
                open={openPDF}
                handleClose={() => {setOpenPDF(false)}}
                file={fileSelected}
            />
            <div style={{width: 350, height: '100%'}}>
                <div style={{padding: 10, backgroundColor: '#F9F9F9', height: '100%', overflowY: 'auto'}}>
                    <h2>Archivos adjuntos</h2>
                    {
                        documents.map((doc, i) => {
                            return (
                                <div key={i} className="item-click" onClick={() => {selectFile(`https://mantencion.icv.cl${doc.url}`)}}>
                                    <p><FontAwesomeIcon icon={faFilePdf} /> {doc.name}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Drawer>
    )
}