import {Dialog, DialogTitle, DialogContent, ListItemButton} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSitesContext } from '../context';
import { ListItemText } from '@mui/material';

const SeleccionarObraDialog = ({open, handleClose}) => {

    const {sites, setSiteSelected} = useSitesContext()
    const [obras, setObras] = useState([])

    useEffect(() => {
        if (sites.length > 0) {
            setObras(sites.filter(site => {if (site.status) return site}))
        }
    }, [sites])

    return(
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Seleccione Obra</DialogTitle>
            <DialogContent>
                    <ListItemButton onClick={() => {setSiteSelected('none'); setTimeout(() => {
                        handleClose('Selected')
                    }, 500);}}>
                        <ListItemText primary={'Todas las obras'}/>
                    </ListItemButton>
                    {
                        obras.map((obra, i)=>{
                            return (
                                <ListItemButton key={i} onClick={() => {setSiteSelected(obra._id); setTimeout(() => {
                                    handleClose('Selected')
                                }, 500)}}>
                                    <ListItemText primary={obra.descripcion}/>
                                </ListItemButton>
                            )
                        })
                    }
            </DialogContent>
        </Dialog>
    )
}

export default SeleccionarObraDialog